import { useEffect, useState } from 'react';
import { Console } from 'console-feed';
import { TitleUIRow } from 'layout/TitleUI';
import { deploymentService } from '../../../services/deployment.service';
import { Alert, Button, Skeleton, Table, Tooltip, Typography } from 'antd';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { floatingRight, logsStyle, logsText } from 'utils/styles';
import Editor from '@monaco-editor/react';
import { FullScreenEditor } from '../../SharedComponents/FullScreenView/FullScreenView';
import { Deploymentk8sEventsList } from './Deploymentk8sEventsList';
import { iDeployment } from 'shared/deployment';

const { Title } = Typography;

interface iServiceLogsProps {
  serviceName: string;
  deployment: iDeployment;
  container: string;
  describe: object;
  yamlPod: string;
}

export function ServiceLogs({ serviceName, deployment, container, describe, yamlPod }: iServiceLogsProps) {
  const deploymentId = deployment.id;
  const [projectLogs, setProjectLogs] = useState(null);
  const [inLoad, setInLoad] = useState(null);
  let describePod = [];
  if (describe && describe['env']) {
    describePod.push({ name: 'Name', value: describe['name'] });
    describePod.push({ name: 'Image', value: describe['image'] });
    for (let i = 0; i < describe['env'].length; i++) {
      describePod.push({ name: describe['env'][i].name, value: describe['env'][i].value });
    }
  }

  async function getProjectLogs(deploymentId: number, serviceName: string, container: string) {
    let logsAnswer = await deploymentService.getServiceLogs(deploymentId, serviceName, container);
    const logs = logsAnswer.data;
    let state = { logs: [] };
    if (logs?.status === 500) return state.logs;
    Object.keys(logs).forEach(elem => {
      if (!logs[elem] || !logs[elem]['logs']) return;
      let temp = `${logs[elem]['logs']}`.split('\n');
      temp.forEach(item => state.logs.unshift({ method: 'info', data: [item] }));
    });
    return state.logs;
  }

  const getlogs = async () => {
    setInLoad(true);
    let new_logs = await getProjectLogs(deploymentId, serviceName, container);
    setProjectLogs(new_logs);
    setInLoad(false);
  };

  useEffect(() => {
    let interval = null;
    getlogs();
    interval = setInterval(async () => {
      setInLoad(true);
      let new_logs = await getProjectLogs(deploymentId, serviceName, container);
      setProjectLogs(new_logs);
      setInLoad(false);
    }, 30000);
    return () => {
      setProjectLogs(null);
      clearInterval(interval);
    };
  }, [serviceName, deploymentId, container]);

  const ReloadBtn = (
    <Tooltip color="#115EA3" placement="left" title="Click here to reload the logs">
      <Button disabled={inLoad} onClick={getlogs} style={floatingRight}>
        {inLoad ? <LoadingOutlined /> : <ReloadOutlined />} Reload
      </Button>
    </Tooltip>
  );

  let projectLogsUI = null;
  if (projectLogs === null) {
    projectLogsUI = <Skeleton active={true} loading={true} />;
  } else if (projectLogs?.length === 0) {
    projectLogsUI = <Alert type="info" message="No logs for this pod." action={ReloadBtn} />;
  } else {
    projectLogsUI = (
      <>
        <Title level={5}>
          Logs
          {ReloadBtn}
        </Title>
        <div style={logsStyle}>
          <Console logs={projectLogs} variant="light" styles={logsText} />
        </div>
      </>
    );
  }

  const isFullscreen = false;

  return (
    <>
      <TitleUIRow title="Pods Logs" />
      <Title level={5}>{serviceName}</Title>
      <Title level={5}>Describe</Title>
      <FullScreenEditor isFullscreen={isFullscreen}>
        <Editor height={isFullscreen ? '100vh' : '300px'} width={`100%`} language={`yaml`} value={yamlPod || ''} theme={`GitHub`} defaultValue="" />
      </FullScreenEditor>
      <Title level={5}>Events</Title>
      <Deploymentk8sEventsList deployment={deployment} podName={serviceName} />

      {projectLogsUI}
    </>
  );
}
