import gql from 'graphql-tag';
import MetacontrollerLogs from '../monitoring/metacontroller';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useGqlDeploymentById } from '../../../services/deployment.service';
import { DeploymentNamespace } from './General/DeploymentNamespace';
import { iDeployment } from 'shared/deployment';
import { DeploymentWidgetsRow } from '../DeploymentWidgetsRow';
import { TitleUIRow } from 'layout/TitleUI';
import { serviceToIcon } from '../ResourcesStates/ResourcesStates';
import { ServiceLogs } from '../monitoring/logs';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { useAuthedQuery } from 'utils/qlAuth';
import { innerSidebar, orangeColor, redColor, siderStyle, topMargined, topPadding } from 'utils/styles';
import { useUser } from 'utils/common';
import { Button, Col, Menu, Row, Skeleton, Tabs } from 'antd';
import { CloudServerOutlined, CodeOutlined, WarningOutlined } from '@ant-design/icons';
import K8sEvents from '../monitoring/k8sEvents';
import { YamlTab } from './General/YamlTab';
import { RoutesTab } from './General/RoutesTab';
import { PodsLogs } from '../PodsLogs/PodsLogs';
import { DeploymentPvcList } from '../monitoring/DeploymentPvcList';
import { DeploymentWebsshConsole } from '../DeploymentWebssh/DeploymentWebssh';

export function DeploymentLogsPage() {
  const history = useHistory();
  // @ts-ignore
  const { id, tabName } = useParams();
  const user = useUser();
  const dpQuery = useGqlDeploymentById(id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};
  const error = dpQuery.error;
  const loading = dpQuery.loading;

  let tabNameType = String(tabName || '').split('_')[0] || `namespace`;

  if (!tabNameType) {
    history.push(`/app/${id}/logs/namespace`);
  }

  if (error) {
    return (
      <Skeleton active={false} loading={false}>
        {JSON.stringify(error)}
      </Skeleton>
    );
  }

  if (loading || !deployment || !deployment.isReady) {
    return <Skeleton active={true} loading={true} />;
  }
  if (!deployment.isReady) {
    history.push(`/app/${deployment.id}/logs/namespace`);
  }

  const handleClick = (e: { key: any }) => history.push(`/app/${id}/logs/${e.key}`);

  const specsErrors = deployment?.specsErrors;
  const specsWarns = deployment?.specsWarns;

  let YamlIcons = null;
  YamlIcons = specsErrors ? (
    <WarningOutlined twoToneColor={'#FF00000'} style={redColor} />
  ) : specsWarns ? (
    <WarningOutlined twoToneColor={'orange'} style={orangeColor} />
  ) : null;

  return (
    <>
      <TitleUIRow title={`Deployment: ${deployment.name}`} />
      <DeploymentWidgetsRow deployment={deployment} />
      <Tabs
        defaultActiveKey={tabName || `namespace`}
        style={topMargined}
        onChange={(activeKey: string) => history.push(`/app/${id}/logs/${activeKey}`)}
      >
        <Tabs.TabPane tab="Namespace" key="namespace">
          <DeploymentNamespace deployment={deployment} />
        </Tabs.TabPane>
        {userHasPermission(user, UserPermissions.DeploymentLogs) && (
          <Tabs.TabPane tab="Sync logs" key="sync">
            <MetacontrollerLogs deployment={deployment} />
          </Tabs.TabPane>
        )}
        {userHasPermission(user, UserPermissions.DeploymentLogs) && (
          <Tabs.TabPane tab="Pods logs" key="pods">
            <PodsLogs deployment={deployment} tabName={tabName} />
          </Tabs.TabPane>
        )}
        {userHasPermission(user, UserPermissions.DeploymentLogs) && (
          <Tabs.TabPane tab="Events" key="events">
            <K8sEvents deployment={deployment} />
          </Tabs.TabPane>
        )}
        {userHasPermission(user, UserPermissions.DeploymentWebSSH) && (
          <Tabs.TabPane tab={<>{YamlIcons} Yaml </>} key="yaml">
            <YamlTab deployment={deployment} />
          </Tabs.TabPane>
        )}
        {userHasPermission(user, UserPermissions.DeploymentWebSSH) && (
          <Tabs.TabPane tab="Routes" key="routes">
            <RoutesTab deployment={deployment} />
          </Tabs.TabPane>
        )}
        {userHasPermission(user, UserPermissions.DeploymentWebSSH) && (
          <Tabs.TabPane
            tab={
              <>
                <CloudServerOutlined /> Volumes
              </>
            }
            key="volumes"
          >
            <DeploymentPvcList deployment={deployment} />
          </Tabs.TabPane>
        )}
        {userHasPermission(user, UserPermissions.DeploymentWebSSH) && (
          <Tabs.TabPane
            tab={
              <>
                <CodeOutlined /> Console
              </>
            }
            key="console"
          >
            <DeploymentWebsshConsole deployment={deployment} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </>
  );
}
