import { Button, Select, Skeleton, Space } from 'antd';
import { iDeployment } from 'shared/deployment';
import { ServiceLogs } from '../monitoring/logs';
import { useAuthedQuery } from 'utils/qlAuth';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { Deploymentk8sEventsList } from '../monitoring/Deploymentk8sEventsList';

export const PodsLogs = (props: { deployment: iDeployment; tabName: string }) => {
  const history = useHistory();
  const tabName = props.tabName;
  const deployment = props.deployment;

  /**
   * Container name for logs in cubernetes pod
   * @type {string}
   * @todo get container name from the pod describe command ( https://nanoheal.atlassian.net/browse/DP-565 )
   * can be empty string - in this case logs will be taken only if in pod only one container
   */
  let container: string = '';

  const podsTabsRegex = /^pods_(.*)/;

  let serviceName = '';
  let podsTabRegex = /^pods_(.*?)_container_/;
  const containerRegex = /container_(.*)/;
  if (tabName) {
    // let serviceName = tabName.replace(podsTabRegex, '$1');
    if (tabName && podsTabRegex.test(tabName)) {
      serviceName = tabName.match(podsTabRegex)[1];
      if (containerRegex.test(tabName)) {
        container = tabName.match(containerRegex)[1];
      } else {
        container = '';
      }
    } else {
      podsTabRegex = /^pods_(.*)/;
      if (podsTabRegex.test(tabName)) {
        serviceName = tabName.match(podsTabRegex)[1];
      }
    }
  }

  const podsQuery = useAuthedQuery(
    gql`
      query DeploymentsController_getPodsMetrics($deploymentId: Int!) {
        DeploymentsController_getPodsStatus(deploymentId: $deploymentId) {
          error
          status
        }
      }
    `,
    { skip: !Number(deployment.id), variables: { deploymentId: Number(deployment.id) } },
  );

  const loadingStatus = podsQuery?.loading;
  const errorStatus = podsQuery?.error;
  const podsList = podsQuery?.data?.DeploymentsController_getPodsStatus?.status || [];

  if (!serviceName && podsList.length > 0) {
    serviceName = podsList[0].name;
  }

  const query = useAuthedQuery(
    gql`
      query DeploymentsController_getPodsDescribe($deploymentId: Int!, $podName: String) {
        DeploymentsController_getPodsDescribe(deploymentId: $deploymentId, podName: $podName) {
          error
          status
          yaml
        }
      }
    `,
    { skip: !Number(deployment.id) || !serviceName || serviceName === '', variables: { deploymentId: Number(deployment.id), podName: serviceName } },
  );

  if (loadingStatus) {
    return <Skeleton active={true} loading={true} />;
  }

  if (errorStatus || query.error) {
    return (
      <Skeleton active={false} loading={false}>
        Error: {errorStatus?.message || JSON.stringify(query.error)}
      </Skeleton>
    );
  }

  const podContainerList = [];
  let desctibeContiner = {};
  let yamlPod = query.data?.DeploymentsController_getPodsDescribe?.yaml || '';
  if (query.data?.DeploymentsController_getPodsDescribe?.status) {
    const allContainers = query.data?.DeploymentsController_getPodsDescribe?.status[0]?.spec?.containers;
    if (allContainers) {
      allContainers.map(item => {
        podContainerList.push(item.name);
        if (item.name == container) {
          desctibeContiner = item;
        }
      });
      if (container == '') {
        container = podContainerList[0];
        desctibeContiner = allContainers[0];
      } else {
        const position = podContainerList.indexOf(container);
        container = podContainerList[position];
        desctibeContiner = allContainers[position];
      }
    }
  }

  if (!container && podContainerList.length > 0) {
    container = podContainerList[0];
  }

  const changeContainer = (elem: any) => history.push(`/app/${deployment.id}/logs/pods_${serviceName}_container_${elem}`);
  const handleChange = (value: string) => history.push(`/app/${deployment.id}/logs/pods_${value}`);

  return (
    <div>
      <Space direction="horizontal">
        Select Pod
        <Select style={{ width: `100%` }} defaultValue={serviceName} onChange={handleChange}>
          {podsList.map(item => {
            return <Select.Option value={item.name}>{item.name}</Select.Option>;
          })}
        </Select>
      </Space>
      <Space direction="horizontal">
        Select Pod container
        {podContainerList.map(item => (
          <Button
            type={container === item ? `primary` : `default`}
            onClick={() => changeContainer(item)}
            style={{ marginTop: '10px', marginRight: '10px' }}
          >
            {item}
          </Button>
        ))}
      </Space>

      <ServiceLogs serviceName={serviceName} deployment={deployment} container={container} describe={desctibeContiner} yamlPod={yamlPod} />
    </div>
  );
};
